import React from "react";
import { dwebData2 } from "./../data/ens_dict.js";

import SiteCard from "./site_card"
import SiteCardM from "./site_card_m";
import site_properties from "../utils/site_properties"

class SiteItems extends React.Component{



    getSiteCard(site_name, size = "small", showTimeAgo){
        if (site_name){
            switch (size) {
                case "small":
                    return (<SiteCard site={dwebData2['sites'][site_name]} showTimeAgo = {showTimeAgo} color = {this.props.color} key={site_name} />)
    
                case "medium":
                    return (<SiteCardM site={dwebData2['sites'][site_name]} showTimeAgo = {showTimeAgo} color = {this.props.color} key={site_name} />)
                
                default:
                    return (<SiteCard site={dwebData2['sites'][site_name]} showTimeAgo = {showTimeAgo} color = {this.props.color} key={site_name} />)
            }
        }
    }

    getSiteColor() {
        return "row " + this.props.color + "-row row-margin";
    }


    render() {
    
            
        return ( <div className={this.getSiteColor()}>

            {this.props.items.map((site, index) => (
            
            this.getSiteCard(site,this.props.size, this.props.showTimeAgo)
           
            ))} 
            </div>
            )
    }
}

export default SiteItems;