import React from "react";
import { dwebData2 } from "./../data/ens_dict.js";

import SitesInfiScroll from "./sites_infiscroll"

class Recent extends React.Component{


    render() {
    
        return ( <SitesInfiScroll sites={dwebData2['recent']} showTimeAgo = {true} title = "Recently Updated  dWebsites" />);
    }
}

export default Recent;