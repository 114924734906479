import React from "react";

import TimeAgo from 'javascript-time-ago'

// English.
import en from 'javascript-time-ago/locale/en'

TimeAgo.addLocale(en)


const timeAgo = new TimeAgo('en-US')

class SiteCard extends React.Component{


    getScreenshotUrl(){
        if (this.props.site.sc){
            try {
                
                return './images/screenshots/'+this.props.site.name+'_screenshot.jpg';
            }
            catch {
                return './images/logo_placeholder.png';
            }
        } else{
            return './images/logo_placeholder.png';
        }
    }

    getLink(){
        return 'https://'+this.props.site.name + '.eth.link';
    }

    getCardColor() {
        // if (this.props.color == 'pink')
        //     return ("card card-pink");

        // if (this.props.color == 'purple')
        //     return ("card card-purple");
        return ("card");

    }

    getTimeAgo(show){
        if (show) 
            return (<div className="timeagosmall">
                            {timeAgo.format(Date.parse(this.props.site.update))}
                    </div>)
        else
            return '';

    }


    render(){

        let card_color = this.getCardColor();
        const cardClasses = card_color+' c-pointer';
          
      

        return (<div className="col-lg-3 col-xl-3">
        <div className={cardClasses} onClick={()=> window.open(this.getLink(), "_blank")}>
            <div className="card-body">
                <div className="row m-b-30">
                    <div className="col-md-12 col-xxl-12 ui-card-main">
                        <div className="ui-image-main mb-4 mb-xxl-4 mb-md-0">
                            <div className="new-arrivals-img-contnent">
                                <img className="img-fluid" src={this.getScreenshotUrl()} alt="" />
                            </div>
                        </div>
                        <div className="dwebsite-title">
                            {this.getTimeAgo(this.props.showTimeAgo)}
                            <b><a className="link-dwebsite-title" href={this.getLink()} rel="noopener" target="_blank" >{this.props.site.title}</a></b>
                            <div className="dwebsite-description-m">{this.props.site.desc}.</div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        </div>);
    }
}

export default SiteCard;