import React from "react";
import {
    Link,
    withRouter
  } from "react-router-dom";
  import ScrollToTop from "./scroll_to_top";


  const FancyLink = React.forwardRef((props, ref) => (
    <a ref={ref} {...props} className="ai-icon sidebar-item">{props.children}</a>
  ))
  
  

function LeftSideMenuItem(props){
    return (<li className={(props.isActive?'mm-active':'sidebar-item')} id={props.value.link}>
                            <Link to={props.value.link} component={FancyLink} >
							<i className={['sidebar-link', props.value.class_icon].join(' ')}></i>
							<span className="nav-text sidebar-link">{props.value.label}</span>
						</Link>
                    </li>);
}


const LeftSideBar = withRouter(props => <LeftSideBar1 {...props}/>);

class LeftSideBar1 extends React.Component{

    constructor(props) {
        super(props);

        
        this.left_menu = [{label: 'Home', link: '/', class_icon: 'flaticon-381-home-2'}, 
        {label: 'New', link: '/new', class_icon: 'flaticon-381-price-tag'},
        {label: 'Popular', link: '/popular', class_icon: 'flaticon-381-list'},
        {label: 'Recent', link: '/recently-updated', class_icon: 'flaticon-381-clock'}, 
        {label: 'Browse', link: '/browse', class_icon: 'flaticon-381-internet'}];
      }

    render() {

       
        return (
        <div id="left_sidebar"  className={this.props.active ? 'deznav left-sidebar-open' : 'deznav'}>
            <div className="deznav-scroll">
                <ul className="metismenu" id="menu">
                    <ScrollToTop />
                       {this.left_menu.map((menu_item, index) => (
                            <LeftSideMenuItem key={index} value={menu_item} isActive={this.props.location.pathname===menu_item.link}/>
                        ))}
                </ul>

			      </div>
        </div>)

    }
}

export default LeftSideBar;