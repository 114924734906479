import React from "react";
import { dwebData2 } from "./../data/ens_dict.js";

import SitesInfiScroll from "./sites_infiscroll"

class New extends React.Component{


    render() {

        return ( <SitesInfiScroll sites={dwebData2['new']} showTimeAgo = {true} title="New dWebsites" />);
    }
}

export default New;