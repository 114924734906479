


const site_properties  = {
    getName: function(site){
        return site.name || site.n;
    }, 

    getTitle: function(site){
        return site.title || site.t;
    },

    getDesc: function(site){
        return site.desc || site.d;
    },
    getUpdate: function(site){
        return site.update || site.u;
    }


}

export default site_properties;