import React from "react";

import TimeAgo from 'javascript-time-ago'

import site_properties from '../utils/site_properties'

// English.
import en from 'javascript-time-ago/locale/en'

TimeAgo.addLocale(en)


const timeAgo = new TimeAgo('en-US')

class SiteCardM extends React.Component{


    getScreenshotUrl(){
        if (this.props.site.sc){
            try {
                return './images/screenshots/'+site_properties.getName(this.props.site)+'_screenshot.jpg';
            }
            catch {
                return './images/logo_placeholder.png';
            }
        } else{
            return './images/logo_placeholder.png';
        }
    }

    getLink(){
        return 'https://'+site_properties.getName(this.props.site) + '.eth.link';
    }

    getCardColor() {

        return "card";
    }

    getTimeAgo(show){
        if (show) 
            return (<div className="timeagosmall">
                            {timeAgo.format(Date.parse(site_properties.getUpdate(this.props.site)))}
                    </div>)
        else
            return '';

    }

    render(){
        let card_color = this.getCardColor();
        const cardClasses = card_color+' c-pointer';

        return (
        <div className="col-lg-12 col-xl-4">
            <a href={this.getLink()} target="_blank">
                <div className={cardClasses} >
                    <div className="card-body">
                        <div className="row m-b-30">
                                <div className="new-arrival-product mb-4 mb-xxl-4 mb-md-0">
                                    <div className="medium-img">
                                        <img className="img-fluid" src={this.getScreenshotUrl()} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="dwebsite-title">
                                {this.getTimeAgo(this.props.showTimeAgo)}
                                <div className="link-dwebsite-title" href={this.getLink()} rel="noopener" target="_blank" ><b>{site_properties.getTitle(this.props.site)}</b></div>
                                <div className="dwebsite-description-m">{site_properties.getDesc(this.props.site)}.</div>
                            </div>
                    </div>
                </div>
            </a>
        </div>);
    }
}

export default SiteCardM;