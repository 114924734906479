import React from "react";
import { dwebData2 } from "./../data/ens_dict.js";

import SitesInfiScroll from "./sites_infiscroll"

class Browse extends React.Component{


    render() {
    
        return ( <SitesInfiScroll sites={Object.keys(dwebData2['sites'])} showTimeAgo = {false} title="Browse All dWebsites" />);
    }
}

export default Browse;