import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import Footer from "./components/footer";

import HeaderWithRouter from "./components/header";
import New from "./components/new";
import Popular from "./components/popular";
import Recent from "./components/recent";
import Search from "./components/search";
import Browse from "./components/browse";
import ScrollToTop from "./components/scroll_to_top";
import HomePage from "./components/home_page"



import { dwebData2 } from "./data/ens_dict.js";

import "./fonts/GoogleFonts.css";
import "./css/style.css";
import "./icons/flaticon/flaticon.css";

import {
    HashRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import InfoPage from "./components/info_page";



class App extends React.Component {

    

    render() {
        const newDWebsites = dwebData2['new'].slice(0,3);
        const popular_sample = dwebData2['popular'].sort(() => Math.random() - 0.5).slice(0,3);
        const recent = dwebData2['recent'].slice(0,3);
        const allDWebsites = Object.keys(dwebData2['sites']).sort(() => Math.random() - 0.5).slice(0,3);

        //const IPFSPubSub = React.lazy(() => import('./components/ipfs_pubsub'));

        const IPFSPubSub = React.lazy(() => {
            return new Promise(resolve => {
              setTimeout(() => resolve(import('./components/ipfs_pubsub')), 3000);
            });
          });


     
        return (<Router basename="/">
                  <ScrollToTop />
  
    <HeaderWithRouter />

 
    <div className="content-body" style={{minHeight: '800px'}} >
 
        <Switch>
        <Route exact path="/">
            <HomePage newDWebsites={newDWebsites} popular_sample={popular_sample}  recent={recent} allDWebsites={allDWebsites} />
        </Route>
        
        <Route path="/browse">
        <div className="container-fluid main-wrapper blue-row">
        <ScrollToTop />
         <Browse />
         </div>
        </Route>
        <Route path="/new">
        <div className="container-fluid main-wrapper red-row">
        <ScrollToTop />
         <New />
         </div>
        </Route>
        <Route path="/popular">
        <div className="container-fluid main-wrapper yellow-row">
        <ScrollToTop />
         <Popular />
         </div>
        </Route>
        <Route path="/recently-updated">
        <div className="container-fluid main-wrapper green-row">
        <ScrollToTop />
         <Recent />
         </div>
        </Route>
        <Route path="/search">
        <div className="container-fluid main-wrapper">
        <ScrollToTop />
         <Search />
         </div>
        </Route>
        <Route path="/about-us">
        <ScrollToTop />
         <InfoPage keyType="about-us" />
        </Route>
        <Route path="/privacy-policy">
        <ScrollToTop />
         <InfoPage keyType="privacy-policy" />
        </Route>
    </Switch>
       
    </div>
    <Suspense fallback={<></>}>
        <IPFSPubSub />
    </Suspense>
    <Footer />
</Router>);
      }
}

ReactDOM.render(<App />, document.getElementById("main-wrapper"));