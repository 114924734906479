import React from "react";
import { dwebData2 } from "./../data/ens_dict.js";

import SitesInfiScroll from "./sites_infiscroll"

class Popular extends React.Component{


    render() {
    
        return ( <SitesInfiScroll sites={dwebData2['popular']} showTimeAgo = {false} color="yellow" title="Popular dWebsites" />);
    }
}

export default Popular;