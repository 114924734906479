import SiteItems from './site_items';
import ScrollToTop from "./scroll_to_top";
import React from 'react';

import {
    Link
} from "react-router-dom";


function TopSection(props){

    const class_name = 'page-titles d-flex ' + props.section_color + '-header';
    return (
        <>
            <Link to={props.section_link}>
                <div className={class_name}>
                        <div className="h2 mb-0 text-gray-800">{props.section_name}</div> 
                        <button className="btn btn-primary ml-auto btn-showall">More</button>
                </div>
            </Link>
            <SiteItems items={props.items} size="medium" showTimeAgo = {props.showTimeAgo} color={props.section_color} key={props.section_key}/>
        </>
    )
}

function HomePage({newDWebsites, popular_sample, recent, allDWebsites}){

    return (
    <div className="container-fluid page-background main-wrapper">
        <ScrollToTop />
        <div className="page-border d-flex card-purple"></div>
        <div  className="row">
            <div className="col-lg-12 col-xl-12">
                <div className="tagline text-black fs-24">
                    <div>
                    Esteroids is a tool for the Web3 community to discover the decentralized web. Made by the community - for the community.
                    </div>
                </div>
            </div>
        </div>
        <TopSection items={newDWebsites} section_name="New" section_link="/new" showTimeAgo = {true} section_color="red" section_key="top_new"  />
        <TopSection items={popular_sample} section_name="Popular" section_link="/popular" showTimeAgo = {false} section_color="yellow" section_key="top_popular"  />
        <TopSection items={recent} section_name="Recently Updated" section_link="/recently-updated" showTimeAgo = {true} section_color="green" section_key="top_recent"  />
        <TopSection items={allDWebsites} section_name="All dWebsites" section_link="/browse" showTimeAgo = {false} section_color="blue" section_key="top_all"  />
       
    </div>)

}


export default HomePage;