import React from "react";
import AboutUs from "./about_us";
import PrivacyPolicy from "./privacy_policy";

class InfoPage extends React.Component{
   

    render() {

        var Component = AboutUs;
        if (this.props.keyType==='privacy-policy'){
            Component = PrivacyPolicy;
        }
    
        return ( <><div className="page-titles d-flex card-purple">
                        
                    </div>
                    <div className="col-lg-12">
                        <div className="card mt-3">
                            <div className="card-body">
                                <Component /> 
                            </div>
                        </div>
                    </div></>);
    }
}

export default InfoPage;