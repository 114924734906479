import React from "react";
import { dwebData2 } from "../data/ens_dict.js";

import InfiniteScroll from "react-infinite-scroll-component";
import SiteCardM from "./site_card_m"
import site_properties from "../utils/site_properties"

class SitesInfiScroll extends React.Component{

    constructor(props) {
        super(props);
    
        var curPos = 12;
        
        var hasMore = (this.props.sites.length>=curPos);
        this.state = {
            items: this.props.sites.slice(0,curPos),
            curPos: curPos,
            hasMore: hasMore
            };
    
    }


    fetchMoreData = () => {
        var curPos = this.state.curPos;
        curPos = curPos + 12;
        var new_items = this.props.sites.slice(0,curPos);
        var hasMore = this.props.sites.length>=curPos;

        
        this.setState({
        items: new_items,
        curPos: curPos,
        hasMore: hasMore
        });
    }



    render() {

    
        return ( <InfiniteScroll
            dataLength={this.state.items.length}
            next={this.fetchMoreData}
            hasMore={this.state.hasMore}
            loader={<h4>Loading...</h4>}
            >
            <div className="row row-margin">
            {this.state.items.map((site_name, index) => (
                <SiteCardM key={site_name} site={dwebData2['sites'][site_name]} showTimeAgo = {this.props.showTimeAgo} color = "purple" />   
            ))}
            </div>
            </InfiniteScroll>);
    }
}
    
export default SitesInfiScroll;