import React, { Component } from "react";
import { withRouter } from "react-router";

import { dwebData2 } from "./../data/ens_dict.js";

import SiteItems from './site_items'

class SearchInternal extends Component{

	constructor(props) {
		super(props);
		this.setSearchStringState = this.setSearchStringState.bind(this);
        this.state = {
            search_string: '',
        };
     }

	filterIt(obj, searchKey) {
	  let arr =  Object.entries(obj);
	  let lowerCaseSearchKey = searchKey.toLowerCase()
	  let filtered_arr =  arr.filter(function(site) {
	    let site_details = Object.entries(site[1]) 
	    return ( 
	        (site_details[0][1].toLowerCase().search(lowerCaseSearchKey) >= 0) || 
	        (site_details[1][1].toLowerCase().search(lowerCaseSearchKey) >= 0) || 
	        (site_details[2][1].toLowerCase().search(lowerCaseSearchKey) >= 0))
	   });

	  let only_names_arr = filtered_arr.flat().filter(function(val, ind) {return ind % 2 == 0} );

	  return only_names_arr;
	}

	setSearchStringState(search_string) {
		if (search_string !== this.state.search_string) {
			this.setState({search_string: search_string});
			this.props.history.push(this.props.location);
		}
	}

    render() {
    	let search_string = decodeURIComponent(decodeURIComponent(this.props.location.search.substring(3)));
    	this.setSearchStringState(search_string);
    	let search_results = this.filterIt(dwebData2["sites"], this.state.search_string);

		return (
            <>
                <div className="page-titles d-flex blue-header page-titles-first">
                    <h2 className="h2 mb-0 text-gray-800">Showing results for "{this.state.search_string}"</h2>
                </div>
                <SiteItems items={search_results} size="medium" showTimeAgo = {true} color="blue" key="top_recent"/>
            </>
		)
    }
}

var Search = withRouter(SearchInternal);

export default Search;