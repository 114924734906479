import React from "react";
import {Redirect} from "react-router-dom";



class Searchbar extends React.Component{

	constructor(props) {
        super(props);
		this.search = this.search.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.state = {
            search: '',
            perform_search: false
        };
     }

     search() {
		if (this.state.search!=''){
     		this.setState({ perform_search: true });
		}
		
    }

    doSearch() {

    	if (this.state.perform_search) {
    		this.setState({ perform_search: false });
    		let destination = <Redirect
			            to={{
			              pathname: '/search',
			              search: "?s=" + encodeURIComponent(encodeURIComponent(this.state.search)),
			              state: this.state.search
			            }}
			          />
			this.setState({search: ''});

			return destination;

    	} else {
    		return ""
    	}
		
    }


	

    render() {
		
        return ( 
				<div className="input-group input-primary search-area">
					<div className="input-group-append" >
						<span className="input-group-text c-pointer"><i className="flaticon-381-search-2" onClick={this.search}></i></span>
					</div>
					{this.doSearch()}
					<input type="text"   name="dwebsite_search" id="header-search" value={this.state.search} onChange={event => {this.setState({search: event.target.value})}}  className="form-control" placeholder="Search dWebsites" onKeyPress={event => {
                if (event.key === 'Enter') {
                  this.search()
                }
              }}  />
				</div>
        );
    }
}


export default Searchbar;