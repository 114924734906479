
var title_ending = 'Esteroids the frontpage of the decentralized web';

var routes = {
    '/': { pageName: 'Front page of the dWeb',  pageTitle: title_ending},
    '/new': { pageName: 'New',  pageTitle: 'New - '+title_ending},
    '/popular': { pageName: 'Popular',  pageTitle: 'Popular - '+title_ending},
    '/recently-updated': { pageName: 'Recently Updated',  pageTitle: 'Recently Updated - '+title_ending},
    '/browse': { pageName: 'Browse',  pageTitle: 'Browse - '+title_ending},
    '/search': { pageName: 'Search Results',  pageTitle: 'Search Results - '+title_ending},
    '/about-us': { pageName: 'About Us',  pageTitle: 'About Us - '+title_ending},
    '/privacy-policy': { pageName: 'Privacy Policy',  pageTitle: 'Privacy Policy - '+title_ending},

}

export default routes;